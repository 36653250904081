import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // .post("/api/all_addbinifit", { family_id: this.id })
   

 

    fetchLeaves(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/get-coordinator-leaves-to-approve', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },

    sendResponseaAdd(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approval_status:approvestate.approvestate
        }

        let url=`/api/v1/icoordinator-leaves-approve/${approvestate.action_id}`
        axios
          .put(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    responseAll(ctx,approvestate) {
      //console.log(approvestate)
      return new Promise((resolve, reject) => {


        let url=`/api/v1/information_manager/modifications/respond_multiple`
        axios
          .put(url,approvestate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
  
    sendResponse(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approve:approvestate.approvestate
        }

        let url=`/api/v1/coordinator-leaves-approve/${approvestate.action_id}`
        axios
          .post(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },

  },
}
