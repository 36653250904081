import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import Vue from 'vue'
import store from '@/store'

export default function useList() {
  // Use toast

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,},
    
    { key: "user_id", label: "الموظف",formatter: title,},
    { key: "body", label: "الوصف",formatter: title,},
    {
        isActive: false, 
        key: "leave_type_id.name",
        label: "نوع الاجازة",
        formatter: title,
  
      },
    
   
    {
      isActive: false, 
      key: "duration",
      label: "المدة",
      formatter: title,

    },
    
    {
      isActive: false, 
      key: "start",
      label: "تاريخ البدء",
      formatter: title,

    },
    
  

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 1000]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refFamiliesListTable.value.refresh()
  }
  const selectedItem = ref([])

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const onFiltered = filteredItems => {
    // Trigger pagination to update the number of buttons/pages due to filtering
    totalUsers.value = filteredItems.length
    currentPage.value = 1
  }
  const responseAll = (modificationslist, approvestate) => {
    // console.log(modificationslist)
    // console.log(approvestate)
    store
      .dispatch('app-leave/responseAll', {
        modifications: modificationslist,
        approval_status: approvestate,

      })
      .then(response => {
        selectedItem.value = []
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'success',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        refetchData()
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }

  const sendResponse = (action_id, approvestate) => {
    store
      .dispatch('app-leave/sendResponse', {
        action_id,
        approvestate,

      })
      .then(response => {
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'success',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        refetchData()
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  // const products = ref([])

  const fetchLeaves = (ctx, callback) => {
    store
      .dispatch('app-leave/fetchLeaves', {

        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        // console.log(response.data)
      //  console.log(response.data)
        const { data } = response.data
        // totalUsers.value = response.data.meta.total
        callback(data)
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }

  return {
    fetchLeaves,
    onFiltered,

    tableColumns,
    perPage,
    currentPage,
    sendResponse,
    responseAll,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
    selectedItem,
  }
}
